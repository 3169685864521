import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { metaData } from '../data/dummy';
const UserProfile = () => {
  const { currentColor } = useStateContext();
  
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          customFunction={()=>{console.log("Closing")}}
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
       
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {metaData['firstname']} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  {metaData['email']}   </p>

        </div>
      </div>
      
     
    </div>

  );
};

export default UserProfile;
